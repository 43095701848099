import { HomePage } from "../pages/home";
import { Signup } from "../pages/signup";
import { ActivateAccountNotifierPage } from "../pages/activate-account-notifier";
import { ActivateAccountPage } from "../pages/activate-account";
import { ForgotPasswordPage } from "../pages/forgot-password";
import { ResetPasswordPage } from "../pages/reset-password";

export const unauthenticatedUserRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/activate-account",
    children: [
      {
        path: "notification",
        element: <ActivateAccountNotifierPage />,
      },
      {
        path: ":token",
        element: <ActivateAccountPage />,
      },
    ],
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPasswordPage />,
  },
];
